import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {
  Alert,
  Box,
  Button,
  FormControl,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  Snackbar,
  Switch,
  Typography,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import usePolling from "../../../hooks/usePolling";
import { formatData, getFormattedColumns } from "../../../utils/formatData";
import { RenderMissingCountTable } from "../../Table/MissingCountTable";
import { RenderTable } from "../../Table/Table";
import axios from "axios";
import { emitToastSuccess } from "../../../utils/emitToast";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export const Extract = ({ existingSchema }: { existingSchema: boolean }) => {
  const [source_file, setSourceFile] = React.useState<File | undefined>();
  const [readyMadeSQLSelection, setReadyMadeSQLSelection] = useState("");
  const [selectError, setSelectError] = useState(false);
  const { isPolling, error, callAPI, resetSuccess, response, resetResponse } = usePolling();
  const [isSwitchChecked, setIsSwitchChecked] = useState(false); // State for Switch

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Handle submission of SQL code here
    // callAPI("/api/preprocess/extract", "POST", {});
    console.log(e.target.files?.[0]);
    const file = e.target.files?.[0];
    if (file) {
      setSourceFile(file);
      setReadyMadeSQLSelection("");
      const formData = new FormData();
      const user_uuid = localStorage.getItem("userUUID");
      formData.append("file", file);
      formData.append("user_uuid", user_uuid ?? "");
      formData.append("sectionName", "extract_sql_input");

      axios
        .post("/api/import/", formData)
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  useEffect(() => {
    if (response) {
      resetSuccess();
      setSourceFile(undefined);
      setReadyMadeSQLSelection("");
    }
  }, [response, resetSuccess]);

  const handleSubmit = () => {
    console.log(readyMadeSQLSelection);
    setSelectError(false);
    // Handle submission of table selection and category selection here
    if (readyMadeSQLSelection === "" && !source_file) {
      setSelectError(true);
      return;
    } else {
      callAPI("/api/preprocess/extract", "POST", {
        user_uuid: localStorage.getItem("userUUID"),
        schema_name: "ehrqc_" + localStorage.getItem("userUUID"),
        save_file: "extract.csv",
        sql_file_path: source_file ? "extract_sql_input.sql" : readyMadeSQLSelection,
      });
    }
  };

  return (
    <>
      <Box
        width={"100%"}
        display={"flex"}
        justifyContent={"center"}
        flexDirection={"column"}
        alignItems={"center"}
        sx={{ pt: 3, gap: 2, maxWidth: `100%` }} // maxWidth={`calc(100% - 240px)`}
      >
        {!existingSchema && (
          <Alert severity="error">
            Extract requires your existing data from standardisation. It seems that your data has
            been removed. Please run standardisation again.
          </Alert>
        )}
        {existingSchema && (
          <>
            <Typography variant="h4">Extract</Typography>
            <Typography variant="subtitle1">
              This step involves extracting your standardised data. You can provide your own SQL
              file or use some example lab sql files.
            </Typography>

            {!response && (
              <>
                <Button
                  component="label"
                  role={undefined}
                  variant="contained"
                  tabIndex={-1}
                  startIcon={<CloudUploadIcon />}
                >
                  Upload file
                  <VisuallyHiddenInput type="file" accept=".sql" onChange={handleFileUpload} />
                </Button>
                {source_file && (
                  <Alert severity="success">
                    File successfully uploaded. Using file with name: {source_file.name}
                  </Alert>
                )}

                {!source_file && (
                  <FormControl sx={{ m: 1, minWidth: "50%", maxWidth: "80%" }}>
                    <InputLabel id="tableSelectionLabel">Ready made SQL queries</InputLabel>
                    <Select
                      id="tableSelection"
                      labelId="tableSelectionLabel"
                      value={readyMadeSQLSelection}
                      label="Ready made SQL queries"
                      onChange={(e) => setReadyMadeSQLSelection(e.target.value)}
                    >
                      <MenuItem value="">Select</MenuItem>
                      <MenuItem value="omop_labs_episode_date/avg.sql">Labs-avg</MenuItem>
                      <MenuItem value="omop_labs_episode_date/first.sql">Labs-first</MenuItem>
                      <MenuItem value="omop_labs_episode_date/last.sql">Labs-last</MenuItem>
                      <MenuItem value="omop_labs_episode_date/min.sql">Labs-min</MenuItem>
                      <MenuItem value="omop_labs_episode_date/max.sql">Labs-max</MenuItem>
                      <MenuItem value="omop_labs_episode_date/stddev.sql">Labs-stddev</MenuItem>
                    </Select>
                  </FormControl>
                )}
                <Button
                  variant="contained"
                  onClick={handleSubmit}
                  sx={{ m: 2, maxWidth: "fit-content" }}
                >
                  Run Extract
                </Button>

                {selectError && (
                  <Alert severity="error" sx={{ p: 2 }}>
                    Please either import your own sql file or select one before extracting!
                  </Alert>
                )}
              </>
            )}

            {error && (
              <Alert severity="error">Error has occured. Please double check the sql file.</Alert>
            )}

            {isPolling && (
              <Box sx={{ width: "100%" }}>
                <LinearProgress />
              </Box>
            )}

            {response && (
              <>
                <Button variant="contained" color="primary" onClick={() => resetResponse()}>
                  Back
                </Button>
                {response?.missing_count && (
                  <Box>
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"center"}
                      flexDirection={"column"}
                      width={"100%"}
                      height={"100%"}
                    >
                      <Typography variant="h6">Data Extracted</Typography>
                      <Typography>Total Rows: {Object.keys(response.data).length}</Typography>
                      <Typography>Total Columns: {response.columns.length}</Typography>
                      <Typography>
                        Total Missing Counts:{" "}
                        {JSON.parse(response.missing_count).reduce(
                          (acc: number, curr: string) => acc + parseInt(curr),
                          0
                        )}
                      </Typography>
                      {/* <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
                        <Typography variant="body2">Show Missing Counts Table</Typography>
                        <Switch
                          checked={isSwitchChecked}
                          onChange={() => setIsSwitchChecked(!isSwitchChecked)}
                        />
                      </Box>

                      {isSwitchChecked && (
                        <>
                          <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
                            <Typography variant="h6">Statistics</Typography>
                            <RenderMissingCountTable
                              columns={response.columns}
                              missing_count={JSON.parse(response.missing_count)}
                            />
                          </Box>
                        </>
                      )} */}
                    </Box>
                  </Box>
                )}
                {response?.data && (
                  <RenderTable
                    excelExportName="Extract"
                    tableHeading="Extracted Data Table"
                    columns={getFormattedColumns(response.columns, 150)}
                    rows={formatData(response.data, response.columns)}
                  />
                )}
              </>
            )}
          </>
        )}
      </Box>
    </>
  );
};
