import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import FileImport from "../FileImport";
import { StepDataContext } from "../../contexts/StepDataContext";
import { ImportCheckboxesDisplay } from "./ImportCheckboxesDisplay";
import { Box, Typography, Button } from "@mui/material";
import axios from "axios";
import usePolling from "../../hooks/usePolling";

const options = [
  { value: "patients", label: "Patient" },
  { value: "labevents", label: "Labevents" },
  { value: "admissions", label: "Admissions" },
  { value: "chartevents", label: "Chart_Events" },
  { value: "diagnosis", label: "Diagnosis" },
  // { value: "Conceptmaps", label: "Conceptmaps" },
];

const ImportConfigSection = () => {
  const { stepData, updateStepData } = React.useContext(StepDataContext);
  const { callAPI, response } = usePolling();

  const getUserUUID = () => {
    let userUUID = localStorage.getItem("userUUID");

    if (!userUUID) {
      userUUID = uuidv4().replaceAll("-", "");
      localStorage.setItem("userUUID", userUUID);
    }

    return userUUID;
  };

  const userUUID = getUserUUID();

  useEffect(() => {
    const user_uuid = getUserUUID();

    callAPI("/api/setupSchemas", "POST", {
      user_uuid: user_uuid,
    });
    // delete the schema with the user_uuid after 7 days
  }, []);

  useEffect(() => {
    // response is false if schema already exists and has delete pending.
    if (response) {
      const user__uuid = getUserUUID();
      console.log(response);
      axios
        .post("/api/deleteSchema", {
          schema_name: `ehrqc_${user__uuid}`,
        })
        .catch((error) => {
          // Handle the error
          console.error(error);
        });
    }
  }, [response]);

  const [checkboxStates, setCheckboxStates] = useState(() => {
    if (stepData && stepData.import) {
      return stepData.import;
    } else {
      const intialStates = {};

      options.forEach((option) => {
        intialStates[option.value] = {
          checked: false,
        };
      });
      return intialStates;
    }
  });

  // on page render, set the stepdata for import to the checkboxStates for now
  useEffect(() => {
    if (!stepData.import) {
      updateStepData("import", checkboxStates);
    }
  }, []);

  const handleCheckboxChange = (value) => {
    const updateData = { ...stepData.import };
    updateData[value] = { ...updateStepData[value], checked: !checkboxStates[value].checked };
    updateStepData("import", updateData);

    const newStates = { ...checkboxStates };
    newStates[value].checked = !newStates[value].checked;
    setCheckboxStates(newStates);
  };

  // very bad code, need to refactor maybe
  const prepop = () => {
    // trigger useeffect to update stepData for check boxes
    setCheckboxStates({
      patients: { checked: true },
      labevents: { checked: true },
      admissions: { checked: true },
      chartevents: { checked: false },
      diagnosis: { checked: false },
      Conceptmaps: { checked: false },
    });

    //update stepdata for fileData
    const newPatientData = {
      ...stepData.import.patient,
      checked: true,
      column_mapping: {
        patient_id: "uniquepid",
        age: "age",
        gender: "gender",
        dob: null,
        dod: null,
      },
      fileData: [
        {
          admissionheight: "165.1",
          admissionweight: "86.8",
          age: "66",
          apacheadmissiondx: "Sepsis, pulmonary",
          dischargeweight: "79.0",
          ethnicity: "Caucasian",
          gender: "Female",
          hospitaladmitoffset: "-23",
          hospitaladmitsource: "Emergency Department",
          hospitaladmittime24: "10:02:00",
          hospitaldischargelocation: "Skilled Nursing Facility",
          hospitaldischargeoffset: "6355",
          hospitaldischargestatus: "Alive",
          hospitaldischargetime24: "20:20:00",
          hospitaldischargeyear: "2014",
          hospitalid: "73",
          patienthealthsystemstayid: "137239",
          patientunitstayid: "151900",
          uniquepid: "002-10052",
          unitadmitsource: "Emergency Department",
          unitadmittime24: "10:25:00",
          unitdischargelocation: "Floor",
          unitdischargeoffset: "3460",
          unitdischargestatus: "Alive",
          unitdischargetime24: "20:05:00",
          unitstaytype: "admit",
          unittype: "MICU",
          unitvisitnumber: "1",
          wardid: "97",
        },
        {
          admissionheight: "188.0",
          admissionweight: "",
          age: "64",
          apacheadmissiondx: "Sepsis, pulmonary",
          dischargeweight: "93.3",
          ethnicity: "African American",
          gender: "Male",
          hospitaladmitoffset: "-4916",
          hospitaladmitsource: "Floor",
          hospitaladmittime24: "02:03:00",
          hospitaldischargelocation: "Death",
          hospitaldischargeoffset: "23365",
          hospitaldischargestatus: "Expired",
          hospitaldischargetime24: "17:24:00",
          hospitaldischargeyear: "2014",
          hospitalid: "73",
          patienthealthsystemstayid: "140014",
          patientunitstayid: "155503",
          uniquepid: "002-6227",
          unitadmitsource: "Direct Admit",
          unitadmittime24: "11:59:00",
          unitdischargelocation: "Floor",
          unitdischargeoffset: "15154",
          unitdischargestatus: "Alive",
          unitdischargetime24: "00:33:00",
          unitstaytype: "admit",
          unittype: "SICU",
          unitvisitnumber: "1",
          wardid: "85",
        },
        {
          admissionheight: "162.6",
          admissionweight: "53.9",
          age: "53",
          apacheadmissiondx: "Sepsis, pulmonary",
          dischargeweight: "68.7",
          ethnicity: "Caucasian",
          gender: "Female",
          hospitaladmitoffset: "-9",
          hospitaladmitsource: "Emergency Department",
          hospitaladmittime24: "16:30:00",
          hospitaldischargelocation: "Rehabilitation",
          hospitaldischargeoffset: "39076",
          hospitaldischargestatus: "Alive",
          hospitaldischargetime24: "19:55:00",
          hospitaldischargeyear: "2014",
          hospitalid: "63",
          patienthealthsystemstayid: "140765",
          patientunitstayid: "156476",
          uniquepid: "002-18273",
          unitadmitsource: "Emergency Department",
          unitadmittime24: "16:39:00",
          unitdischargelocation: "Step-Down Unit (SDU)",
          unitdischargeoffset: "24539",
          unitdischargestatus: "Alive",
          unitdischargetime24: "17:38:00",
          unitstaytype: "admit",
          unittype: "Med-Surg ICU",
          unitvisitnumber: "1",
          wardid: "95",
        },
        {
          admissionheight: "190.5",
          admissionweight: "130.0",
          age: "57",
          apacheadmissiondx: "Rhythm disturbance (atrial, supraventricular)",
          dischargeweight: "141.1",
          ethnicity: "Caucasian",
          gender: "Male",
          hospitaladmitoffset: "0",
          hospitaladmitsource: "",
          hospitaladmittime24: "22:28:00",
          hospitaldischargelocation: "Home",
          hospitaldischargeoffset: "5544",
          hospitaldischargestatus: "Alive",
          hospitaldischargetime24: "18:52:00",
          hospitaldischargeyear: "2014",
          hospitalid: "66",
          patienthealthsystemstayid: "164441",
          patientunitstayid: "187016",
          uniquepid: "002-8840",
          unitadmitsource: "Emergency Department",
          unitadmittime24: "22:28:00",
          unitdischargelocation: "Floor",
          unitdischargeoffset: "2654",
          unitdischargestatus: "Alive",
          unitdischargetime24: "18:42:00",
          unitstaytype: "admit",
          unittype: "Med-Surg ICU",
          unitvisitnumber: "1",
          wardid: "90",
        },
        {
          admissionheight: "170.2",
          admissionweight: "",
          age: "75",
          apacheadmissiondx: "Sepsis, cutaneous/soft tissue",
          dischargeweight: "86.8",
          ethnicity: "Caucasian",
          gender: "Female",
          hospitaladmitoffset: "-23",
          hospitaladmitsource: "Emergency Department",
          hospitaladmittime24: "03:07:00",
          hospitaldischargelocation: "Other Hospital",
          hospitaldischargeoffset: "1520",
          hospitaldischargestatus: "Alive",
          hospitaldischargetime24: "04:50:00",
          hospitaldischargeyear: "2014",
          hospitalid: "71",
          patienthealthsystemstayid: "167453",
          patientunitstayid: "190833",
          uniquepid: "002-72569",
          unitadmitsource: "Emergency Department",
          unitadmittime24: "03:30:00",
          unitdischargelocation: "Other Hospital",
          unitdischargeoffset: "1494",
          unitdischargestatus: "Alive",
          unitdischargetime24: "04:24:00",
          unitstaytype: "admit",
          unittype: "Med-Surg ICU",
          unitvisitnumber: "1",
          wardid: "87",
        },
      ],
      fileColumns: Object.keys({
        admissionheight: "165.1",
        admissionweight: "86.8",
        age: "66",
        apacheadmissiondx: "Sepsis, pulmonary",
        dischargeweight: "79.0",
        ethnicity: "Caucasian",
        gender: "Female",
        hospitaladmitoffset: "-23",
        hospitaladmitsource: "Emergency Department",
        hospitaladmittime24: "10:02:00",
        hospitaldischargelocation: "Skilled Nursing Facility",
        hospitaldischargeoffset: "6355",
        hospitaldischargestatus: "Alive",
        hospitaldischargetime24: "20:20:00",
        hospitaldischargeyear: "2014",
        hospitalid: "73",
        patienthealthsystemstayid: "137239",
        patientunitstayid: "151900",
        uniquepid: "002-10052",
        unitadmitsource: "Emergency Department",
        unitadmittime24: "10:25:00",
        unitdischargelocation: "Floor",
        unitdischargeoffset: "3460",
        unitdischargestatus: "Alive",
        unitdischargetime24: "20:05:00",
        unitstaytype: "admit",
        unittype: "MICU",
        unitvisitnumber: "1",
        wardid: "97",
      }),
    };

    const newLabData = {
      ...stepData.import.labevents,
      checked: true,
      column_mapping: {
        labevent_id: "labid",
        patient_id: null,
        episode_id: "patientunitstayid",
        specimen_id: null,
        itemid: "labname",
        charttime: "labresultoffset",
        storetime: null,
        value: "labresulttext",
        valuenum: "labresult",
        valueuom: "labmeasurenamesystem",
        ref_range_lower: null,
        ref_range_upper: null,
        flag: null,
        priority: null,
        comments: null,
      },
      fileData: [
        {
          labid: "57763223",
          labmeasurenameinterface: "",
          labmeasurenamesystem: "",
          labname: "urinary specific gravity",
          labresult: "1.028",
          labresultoffset: "2043",
          labresultrevisedoffset: "2161",
          labresulttext: "1.028",
          labtypeid: "4",
          patientunitstayid: "151900",
        },
        {
          labid: "57260918",
          labmeasurenameinterface: "mg/dL",
          labmeasurenamesystem: "mg/dL",
          labname: "bedside glucose",
          labresult: "156.0",
          labresultoffset: "6195",
          labresultrevisedoffset: "6199",
          labresulttext: "156",
          labtypeid: "4",
          patientunitstayid: "151900",
        },
        {
          labid: "49719780",
          labmeasurenameinterface: "mcg/mL",
          labmeasurenamesystem: "mcg/mL",
          labname: "Vancomycin - trough",
          labresult: "15.5",
          labresultoffset: "3080",
          labresultrevisedoffset: "3126",
          labresulttext: "15.5",
          labtypeid: "2",
          patientunitstayid: "151900",
        },
        {
          labid: "58744822",
          labmeasurenameinterface: "mcUnits/mL",
          labmeasurenamesystem: "mcU/ml",
          labname: "TSH",
          labresult: "1.864",
          labresultoffset: "365",
          labresultrevisedoffset: "419",
          labresulttext: "1.864",
          labtypeid: "4",
          patientunitstayid: "151900",
        },
        {
          labid: "44237005",
          labmeasurenameinterface: "g/dL",
          labmeasurenamesystem: "g/dL",
          labname: "total protein",
          labresult: "6.3",
          labresultoffset: "-265",
          labresultrevisedoffset: "-206",
          labresulttext: "6.3",
          labtypeid: "1",
          patientunitstayid: "151900",
        },
        {
          labid: "46484124",
          labmeasurenameinterface: "mg/dL",
          labmeasurenamesystem: "mg/dL",
          labname: "calcium",
          labresult: "7.9",
          labresultoffset: "1262",
          labresultrevisedoffset: "1572",
          labresulttext: "7.9",
          labtypeid: "1",
          patientunitstayid: "151900",
        },
        {
          labid: "51804231",
          labmeasurenameinterface: "%",
          labmeasurenamesystem: "%",
          labname: "-eos",
          labresult: "1.0",
          labresultoffset: "4605",
          labresultrevisedoffset: "4636",
          labresulttext: "1",
          labtypeid: "3",
          patientunitstayid: "151900",
        },
        {
          labid: "48092562",
          labmeasurenameinterface: "ng/mL",
          labmeasurenamesystem: "ng/mL",
          labname: "troponin - I",
          labresult: "0.24",
          labresultoffset: "1262",
          labresultrevisedoffset: "1314",
          labresulttext: "0.24",
          labtypeid: "1",
          patientunitstayid: "151900",
        },
        {
          labid: "44236997",
          labmeasurenameinterface: "mmol/L",
          labmeasurenamesystem: "mmol/L",
          labname: "potassium",
          labresult: "3.9",
          labresultoffset: "-265",
          labresultrevisedoffset: "-206",
          labresulttext: "3.9",
          labtypeid: "1",
          patientunitstayid: "151900",
        },
        {
          labid: "59131753",
          labmeasurenameinterface: "mg/dL",
          labmeasurenamesystem: "mg/dL",
          labname: "bedside glucose",
          labresult: "103.0",
          labresultoffset: "5445",
          labresultrevisedoffset: "5450",
          labresulttext: "103",
          labtypeid: "4",
          patientunitstayid: "151900",
        },
      ],
      fileColumns: Object.keys({
        labid: "57763223",
        labmeasurenameinterface: "",
        labmeasurenamesystem: "",
        labname: "urinary specific gravity",
        labresult: "1.028",
        labresultoffset: "2043",
        labresultrevisedoffset: "2161",
        labresulttext: "1.028",
        labtypeid: "4",
        patientunitstayid: "151900",
      }),
    };

    const newAdmissionData = {
      ...stepData.import.admissions,
      checked: true,
      column_mapping: {
        patient_id: "uniquepid",
        episode_id: "patientunitstayid",
        admittime: "unitadmittime24",
        dischtime: "unitdischargeoffset",
        deathtime: null,
        admission_type: "unittype",
        admission_location: "hospitaladmitsource",
        discharge_location: "hospitaldischargelocation",
        insurance: null,
        language: null,
        marital_status: null,
        ethnicity: null,
        edregtime: null,
        edouttime: "hospitaldischargeyear",
        hospital_expire_flag: "unitdischargestatus",
      },
      fileData: [
        {
          admissionheight: "165.1",
          admissionweight: "86.8",
          age: "66",
          apacheadmissiondx: "Sepsis, pulmonary",
          dischargeweight: "79.0",
          ethnicity: "Caucasian",
          gender: "Female",
          hospitaladmitoffset: "-23",
          hospitaladmitsource: "Emergency Department",
          hospitaladmittime24: "10:02:00",
          hospitaldischargelocation: "Skilled Nursing Facility",
          hospitaldischargeoffset: "6355",
          hospitaldischargestatus: "Alive",
          hospitaldischargetime24: "20:20:00",
          hospitaldischargeyear: "2014",
          hospitalid: "73",
          patienthealthsystemstayid: "137239",
          patientunitstayid: "151900",
          uniquepid: "002-10052",
          unitadmitsource: "Emergency Department",
          unitadmittime24: "10:25:00",
          unitdischargelocation: "Floor",
          unitdischargeoffset: "3460",
          unitdischargestatus: "Alive",
          unitdischargetime24: "20:05:00",
          unitstaytype: "admit",
          unittype: "MICU",
          unitvisitnumber: "1",
          wardid: "97",
        },
        {
          admissionheight: "188.0",
          admissionweight: "",
          age: "64",
          apacheadmissiondx: "Sepsis, pulmonary",
          dischargeweight: "93.3",
          ethnicity: "African American",
          gender: "Male",
          hospitaladmitoffset: "-4916",
          hospitaladmitsource: "Floor",
          hospitaladmittime24: "02:03:00",
          hospitaldischargelocation: "Death",
          hospitaldischargeoffset: "23365",
          hospitaldischargestatus: "Expired",
          hospitaldischargetime24: "17:24:00",
          hospitaldischargeyear: "2014",
          hospitalid: "73",
          patienthealthsystemstayid: "140014",
          patientunitstayid: "155503",
          uniquepid: "002-6227",
          unitadmitsource: "Direct Admit",
          unitadmittime24: "11:59:00",
          unitdischargelocation: "Floor",
          unitdischargeoffset: "15154",
          unitdischargestatus: "Alive",
          unitdischargetime24: "00:33:00",
          unitstaytype: "admit",
          unittype: "SICU",
          unitvisitnumber: "1",
          wardid: "85",
        },
        {
          admissionheight: "162.6",
          admissionweight: "53.9",
          age: "53",
          apacheadmissiondx: "Sepsis, pulmonary",
          dischargeweight: "68.7",
          ethnicity: "Caucasian",
          gender: "Female",
          hospitaladmitoffset: "-9",
          hospitaladmitsource: "Emergency Department",
          hospitaladmittime24: "16:30:00",
          hospitaldischargelocation: "Rehabilitation",
          hospitaldischargeoffset: "39076",
          hospitaldischargestatus: "Alive",
          hospitaldischargetime24: "19:55:00",
          hospitaldischargeyear: "2014",
          hospitalid: "63",
          patienthealthsystemstayid: "140765",
          patientunitstayid: "156476",
          uniquepid: "002-18273",
          unitadmitsource: "Emergency Department",
          unitadmittime24: "16:39:00",
          unitdischargelocation: "Step-Down Unit (SDU)",
          unitdischargeoffset: "24539",
          unitdischargestatus: "Alive",
          unitdischargetime24: "17:38:00",
          unitstaytype: "admit",
          unittype: "Med-Surg ICU",
          unitvisitnumber: "1",
          wardid: "95",
        },
        {
          admissionheight: "190.5",
          admissionweight: "130.0",
          age: "57",
          apacheadmissiondx: "Rhythm disturbance (atrial, supraventricular)",
          dischargeweight: "141.1",
          ethnicity: "Caucasian",
          gender: "Male",
          hospitaladmitoffset: "0",
          hospitaladmitsource: "",
          hospitaladmittime24: "22:28:00",
          hospitaldischargelocation: "Home",
          hospitaldischargeoffset: "5544",
          hospitaldischargestatus: "Alive",
          hospitaldischargetime24: "18:52:00",
          hospitaldischargeyear: "2014",
          hospitalid: "66",
          patienthealthsystemstayid: "164441",
          patientunitstayid: "187016",
          uniquepid: "002-8840",
          unitadmitsource: "Emergency Department",
          unitadmittime24: "22:28:00",
          unitdischargelocation: "Floor",
          unitdischargeoffset: "2654",
          unitdischargestatus: "Alive",
          unitdischargetime24: "18:42:00",
          unitstaytype: "admit",
          unittype: "Med-Surg ICU",
          unitvisitnumber: "1",
          wardid: "90",
        },
        {
          admissionheight: "170.2",
          admissionweight: "",
          age: "75",
          apacheadmissiondx: "Sepsis, cutaneous/soft tissue",
          dischargeweight: "86.8",
          ethnicity: "Caucasian",
          gender: "Female",
          hospitaladmitoffset: "-23",
          hospitaladmitsource: "Emergency Department",
          hospitaladmittime24: "03:07:00",
          hospitaldischargelocation: "Other Hospital",
          hospitaldischargeoffset: "1520",
          hospitaldischargestatus: "Alive",
          hospitaldischargetime24: "04:50:00",
          hospitaldischargeyear: "2014",
          hospitalid: "71",
          patienthealthsystemstayid: "167453",
          patientunitstayid: "190833",
          uniquepid: "002-72569",
          unitadmitsource: "Emergency Department",
          unitadmittime24: "03:30:00",
          unitdischargelocation: "Other Hospital",
          unitdischargeoffset: "1494",
          unitdischargestatus: "Alive",
          unitdischargetime24: "04:24:00",
          unitstaytype: "admit",
          unittype: "Med-Surg ICU",
          unitvisitnumber: "1",
          wardid: "87",
        },
        {
          admissionheight: "167.6",
          admissionweight: "57.6",
          age: "54",
          apacheadmissiondx: "Sepsis, pulmonary",
          dischargeweight: "66.7",
          ethnicity: "African American",
          gender: "Male",
          hospitaladmitoffset: "-78",
          hospitaladmitsource: "Emergency Department",
          hospitaladmittime24: "22:20:00",
          hospitaldischargelocation: "Home",
          hospitaldischargeoffset: "5531",
          hospitaldischargestatus: "Alive",
          hospitaldischargetime24: "19:49:00",
          hospitaldischargeyear: "2014",
          hospitalid: "73",
          patienthealthsystemstayid: "178609",
          patientunitstayid: "205112",
          uniquepid: "002-77029",
          unitadmitsource: "Emergency Department",
          unitadmittime24: "23:38:00",
          unitdischargelocation: "Floor",
          unitdischargeoffset: "1651",
          unitdischargestatus: "Alive",
          unitdischargetime24: "03:09:00",
          unitstaytype: "admit",
          unittype: "CCU-CTICU",
          unitvisitnumber: "1",
          wardid: "114",
        },
        {
          admissionheight: "157.5",
          admissionweight: "57.6",
          age: "65",
          apacheadmissiondx: "Sepsis, GI",
          dischargeweight: "61.7",
          ethnicity: "Caucasian",
          gender: "Female",
          hospitaladmitoffset: "-52478",
          hospitaladmitsource: "Operating Room",
          hospitaladmittime24: "20:58:00",
          hospitaldischargelocation: "Home",
          hospitaldischargeoffset: "29602",
          hospitaldischargestatus: "Alive",
          hospitaldischargetime24: "20:58:00",
          hospitaldischargeyear: "2015",
          hospitalid: "73",
          patienthealthsystemstayid: "186521",
          patientunitstayid: "215336",
          uniquepid: "002-21019",
          unitadmitsource: "Floor",
          unitadmittime24: "07:36:00",
          unitdischargelocation: "Other ICU",
          unitdischargeoffset: "2476",
          unitdischargestatus: "Alive",
          unitdischargetime24: "00:52:00",
          unitstaytype: "readmit",
          unittype: "Neuro ICU",
          unitvisitnumber: "2",
          wardid: "100",
        },
        {
          admissionheight: "172.7",
          admissionweight: "",
          age: "65",
          apacheadmissiondx: "Hypovolemia (including dehydration, Do not include shock states)",
          dischargeweight: "67.3",
          ethnicity: "Caucasian",
          gender: "Female",
          hospitaladmitoffset: "-1",
          hospitaladmitsource: "Emergency Department",
          hospitaladmittime24: "01:54:00",
          hospitaldischargelocation: "Home",
          hospitaldischargeoffset: "41435",
          hospitaldischargestatus: "Alive",
          hospitaldischargetime24: "20:30:00",
          hospitaldischargeyear: "2014",
          hospitalid: "73",
          patienthealthsystemstayid: "189349",
          patientunitstayid: "219019",
          uniquepid: "002-48359",
          unitadmitsource: "Emergency Department",
          unitadmittime24: "01:55:00",
          unitdischargelocation: "Floor",
          unitdischargeoffset: "25956",
          unitdischargestatus: "Alive",
          unitdischargetime24: "02:31:00",
          unitstaytype: "admit",
          unittype: "Med-Surg ICU",
          unitvisitnumber: "1",
          wardid: "93",
        },
        {
          admissionheight: "172.7",
          admissionweight: "77.2",
          age: "46",
          apacheadmissiondx: "Sepsis, pulmonary",
          dischargeweight: "87.4",
          ethnicity: "Caucasian",
          gender: "Male",
          hospitaladmitoffset: "-1",
          hospitaladmitsource: "Emergency Department",
          hospitaladmittime24: "03:55:00",
          hospitaldischargelocation: "Death",
          hospitaldischargeoffset: "3592",
          hospitaldischargestatus: "Expired",
          hospitaldischargetime24: "15:48:00",
          hospitaldischargeyear: "2014",
          hospitalid: "73",
          patienthealthsystemstayid: "196089",
          patientunitstayid: "227679",
          uniquepid: "002-9428",
          unitadmitsource: "Emergency Department",
          unitadmittime24: "03:56:00",
          unitdischargelocation: "Death",
          unitdischargeoffset: "3592",
          unitdischargestatus: "Expired",
          unitdischargetime24: "15:48:00",
          unitstaytype: "admit",
          unittype: "Med-Surg ICU",
          unitvisitnumber: "1",
          wardid: "99",
        },
        {
          admissionheight: "171.5",
          admissionweight: "67.3",
          age: "87",
          apacheadmissiondx: "Sepsis, pulmonary",
          dischargeweight: "74.2",
          ethnicity: "Caucasian",
          gender: "Male",
          hospitaladmitoffset: "-8",
          hospitaladmitsource: "Emergency Department",
          hospitaladmittime24: "10:34:00",
          hospitaldischargelocation: "Home",
          hospitaldischargeoffset: "3242",
          hospitaldischargestatus: "Alive",
          hospitaldischargetime24: "16:44:00",
          hospitaldischargeyear: "2015",
          hospitalid: "69",
          patienthealthsystemstayid: "206213",
          patientunitstayid: "240681",
          uniquepid: "002-76845",
          unitadmitsource: "Emergency Department",
          unitadmittime24: "10:42:00",
          unitdischargelocation: "Home",
          unitdischargeoffset: "3242",
          unitdischargestatus: "Alive",
          unitdischargetime24: "16:44:00",
          unitstaytype: "admit",
          unittype: "Med-Surg ICU",
          unitvisitnumber: "1",
          wardid: "98",
        },
      ],
      fileColumns: Object.keys({
        admissionheight: "165.1",
        admissionweight: "86.8",
        age: "66",
        apacheadmissiondx: "Sepsis, pulmonary",
        dischargeweight: "79.0",
        ethnicity: "Caucasian",
        gender: "Female",
        hospitaladmitoffset: "-23",
        hospitaladmitsource: "Emergency Department",
        hospitaladmittime24: "10:02:00",
        hospitaldischargelocation: "Skilled Nursing Facility",
        hospitaldischargeoffset: "6355",
        hospitaldischargestatus: "Alive",
        hospitaldischargetime24: "20:20:00",
        hospitaldischargeyear: "2014",
        hospitalid: "73",
        patienthealthsystemstayid: "137239",
        patientunitstayid: "151900",
        uniquepid: "002-10052",
        unitadmitsource: "Emergency Department",
        unitadmittime24: "10:25:00",
        unitdischargelocation: "Floor",
        unitdischargeoffset: "3460",
        unitdischargestatus: "Alive",
        unitdischargetime24: "20:05:00",
        unitstaytype: "admit",
        unittype: "MICU",
        unitvisitnumber: "1",
        wardid: "97",
      }),
    };

    updateStepData("import", {
      ...stepData.import,
      patients: newPatientData,
      labevents: newLabData,
      admissions: newAdmissionData,
    });
  };

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      justifyContent={"center"}
      sx={{ pt: 3, gap: 2, maxWidth: `100%` }}
    >
      <Typography variant="h3">Import and configure your CSV files</Typography>
      <Typography variant="body1">
        To run our standardise pipeline, you will have to import your CSV files, and map the columns
        names to the corresponding columns.
      </Typography>

      <Button variant="contained" onClick={() => prepop()} sx={{ width: "fit-content" }}>
        Prepopulate
      </Button>

      <Box display={"flex"} justifyContent={"space-around"}>
        <ImportCheckboxesDisplay
          options={options}
          checkboxStates={checkboxStates}
          handleCheckboxChange={handleCheckboxChange}
        />
      </Box>

      <Box display={"flex"} gap={2} flexDirection={"column"} sx={{ maxWidth: `100%` }}>
        {Object.keys(checkboxStates).map((key, index) => {
          const checkbox = checkboxStates[key];
          return (
            checkbox.checked && (
              <FileImport key={"import" + key + index} sectionName={key} user_uuid={userUUID} />
            )
          );
        })}
      </Box>
    </Box>
  );
};

export default ImportConfigSection;
