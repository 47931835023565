import React, { useState } from "react";
import ScatterPlotDataHandler from "./components/ScatterChartDataHandler";
import BarChartDataHandler from "./components/BarChartDataHandler";
import BoxChartDataHandler from "./components/BoxChartDataHandler";
import PieChartDataHandler from "./components/PieChartDataHandler";
import LineChartDataHandler from "./components/LineChartDataHandler";
import ViolinPlotDataHandler from "./components/ViolinPlotDataHandler";
import Papa from "papaparse";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    width: '100vw',
    color: '#333',
    backgroundColor: '#f4f4f9',
    fontFamily: 'Arial, sans-serif',
  },
  content: {
    flex: 1,
    padding: '40px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#ffffff',
    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
    margin: '20px',
    borderRadius: '10px',
    overflowY: 'auto',
  },
  heading: {
    color: '#333',
    marginBottom: '20px',
    fontSize: '2rem',
  },
  buttonContainer: { margin: '20px 0' },
  fileLabel: {
    display: 'block',
    padding: '15px 30px',
    backgroundColor: '#0063b2ff',
    color: '#ffffff',
    marginBottom: '20px',
    textAlign: 'center',
    borderRadius: '5px',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
  },
  fileInput: { display: 'none' },
  fileInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#e1e1e1',
    marginTop: '5px',
    padding: '10px 15px',
    borderRadius: '5px',
    width: '400px',
  },
  fileName: {
    flex: 1,
    color: '#333',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  removeButton: {
    padding: '5px 10px',
    backgroundColor: '#ff6347',
    color: '#ffffff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
  },
  graphDropdown: {
    margin: '20px 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  graphLabel: {
    marginBottom: '10px',
    fontWeight: 'bold',
    fontSize: '1.2rem',
  },
  graphSelect: {
    padding: '12px 20px',
    borderRadius: '10px',
    border: '2px solid #ccc',
    fontSize: '1rem',
    transition: 'border-color 0.3s ease, background-color 0.3s ease',
    outline: 'none',
    appearance: 'none',
    backgroundColor: '#fff',
    backgroundImage: 'url("data:image/svg+xml;utf8,<svg fill=\'none\' stroke=\'black\' stroke-width=\'2\' viewBox=\'0 0 24 24\' xmlns=\'http://www.w3.org/2000/svg\'><path stroke-linecap=\'round\' stroke-linejoin=\'round\' d=\'M19 9l-7 7-7-7\'/></svg>")',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right 10px center',
  },
  exportButton: {
    padding: '15px 30px',
    backgroundColor: '#0063b2ff',
    color: '#ffffff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
    fontSize: '1rem',
    fontWeight: 'bold',
    marginTop: '20px',
  },
  chartsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: '20px',
    marginTop: '20px',
    width: '100%',
  },
  chart: {
    flex: '1 1 45%',
    backgroundColor: '#ffffff',
    borderRadius: '10px',
    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
    padding: '20px',
    margin: '20px 0',
    transition: 'transform 0.3s ease',
  },
};

const FrontPage = () => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [fileNames, setFileNames] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [charts, setCharts] = useState([]);

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setSelectedFiles([...selectedFiles, ...files]);
    setFileNames([...fileNames, ...files.map((file) => file.name)]);

    files.forEach((file) => {
      Papa.parse(file, {
        header: true,
        complete: (results) => {
          setCsvData((prevData) => [...prevData, ...results.data]);
        },
      });
    });
  };

  const removeFile = (index) => {
    const updatedFiles = [...selectedFiles];
    const updatedFileNames = [...fileNames];
    updatedFiles.splice(index, 1);
    updatedFileNames.splice(index, 1);
    setSelectedFiles(updatedFiles);
    setFileNames(updatedFileNames);
  };

  const handleGraphSelect = (selectedGraph) => {
    const newChart = {
      type: selectedGraph,
      data: csvData,
    };
    setCharts([...charts, newChart]);
  };

 

  return (
    <div style={styles.container}>
      <section style={styles.content}>
        <h2 style={styles.heading}>Import Your CSV Files</h2>
        <p>Click the button below to import your CSV files:</p>
        <div style={styles.buttonContainer}>
          <label htmlFor="file-input" style={styles.fileLabel}>
            Import
          </label>
          <input
            id="file-input"
            type="file"
            accept=".csv"
            multiple
            style={styles.fileInput}
            onChange={handleFileChange}
          />
        </div>
        <div>
          {fileNames.map((fileName, index) => (
            <div key={index} style={styles.fileInfo}>
              <span style={styles.fileName}>{fileName}</span>
              <button style={styles.removeButton} onClick={() => removeFile(index)}>Remove</button>
            </div>
          ))}
        </div>

        <div style={styles.graphDropdown}>
          <label htmlFor="graph-select" style={styles.graphLabel}>Select Graph:</label>
          <select id="graph-select" style={styles.graphSelect} onChange={(e) => handleGraphSelect(e.target.value)}>
            <option value="">Select...</option>
            <option value="ScatterPlot">Scatter Plot</option>
            <option value="BarChart">Bar Chart</option>
            <option value="BoxPlot">Box Plot</option>
            <option value="PieChart">Pie Chart</option>
            <option value="LineChart">Line Chart</option>
            <option value="ViolinPlot">Violin Plot</option>
          </select>
        </div>


        <div style={styles.chartsContainer}>
          {charts.map((chart, index) => (
            <div key={index} style={styles.chart}>
              {chart.type === "ScatterPlot" && <ScatterPlotDataHandler csvData={chart.data} />}
              {chart.type === "BarChart" && <BarChartDataHandler csvData={chart.data} />}
              {chart.type === "BoxPlot" && <BoxChartDataHandler csvData={chart.data} />}
              {chart.type === "PieChart" && <PieChartDataHandler csvData={chart.data} />}
              {chart.type === "LineChart" && <LineChartDataHandler csvData={chart.data} />}
              {chart.type === "ViolinPlot" && <ViolinPlotDataHandler csvData={chart.data} />}
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default FrontPage;