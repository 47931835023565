import React from "react";

const About = () => {
  return (
    <div style={{ margin: '50px', textAlign: 'center' }}>
      <h1 style={{ color: '#2c3e50', marginBottom: '20px' }}>About EHR-QC</h1>
      <p style={{
          color: '#34495e',
          fontSize: '18px',
          lineHeight: '1.6',
          maxWidth: '800px',
          margin: 'auto',
          textAlign: 'justify',
          backgroundColor: '#ecf0f1',
          padding: '20px',
          borderRadius: '10px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
        }}>
        The EHRQC process page offers a comprehensive guide on how to use the EHR-QC toolset for extracting, processing, and managing Electronic Health Records (EHRs) data. It includes detailed instructions on the necessary steps to extract EHR data from databases, convert them into a manageable format, and perform various preprocessing tasks to ensure data quality. The page provides a wealth of information on different modules such as 'Extract', 'Transform', and 'Load', each designed to streamline the handling of EHR data for further analysis. This resource is invaluable for researchers and healthcare professionals involved in EHR data analysis, offering tools and scripts to facilitate the handling of large datasets and improve the reliability of health data processing.
      </p>
    </div>
  );
};

export default About;
