import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { About, MainPage, StandardisePage } from "./pages";
import { StepDataContextProvider } from "./contexts/StepDataContext";
import { LayoutWithSideBar } from "./components/LayoutWithSideBar/LayoutWithSideBar";
import { PreprocessPage } from "./pages/PreprocessingPage";
import { TabProvider } from "./contexts/TabContext";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { ImportVocabularyPage } from "./pages/ImportVocabularyPage";
import { CircularProgress } from "@mui/material";

function App() {
  const [existingSchema, setExistingSchema] = useState(false);
  const [pending, setPending] = useState(true);

  // check if the vocabulary has been imported yet. If it hasn't, then load vocab import page, we need to import it.
  useEffect(() => {
    async function checkForVocabularySchema() {
      const response = await axios.post(
        "/api/getExistingSchema",
        {
          schema_name: "ehrqc",
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setExistingSchema(response.data.schema_exists);
      setPending(false);
    }

    checkForVocabularySchema();
  }, []);

  return (
    <>
      {pending && <CircularProgress />}
      {!pending && (
        <>
          <StepDataContextProvider>
            <TabProvider>
              <Router>
                <LayoutWithSideBar>
                  { !existingSchema && <ImportVocabularyPage /> }
                  { existingSchema && (
                    <Routes>
                      <Route path="/" element={<MainPage />} />
                      <Route path="/about" element={<About />} />
                      <Route path="/standardise" element={<StandardisePage />} />
                      <Route path="/preprocess" element={<PreprocessPage />} />
                    </Routes>
                  )}
                  
                </LayoutWithSideBar>
              </Router>
            </TabProvider>
            <ToastContainer limit={1} />
          </StepDataContextProvider>
        </>
      )}
    </>
  );
}

export default App;
