import Box from "@mui/material/Box";
import React from "react";
import { v4 as uuidv4 } from "uuid";

const FrontPage = () => {
  const getUserUUID = () => {
    let userUUID = localStorage.getItem("userUUID");

    if (!userUUID) {
      userUUID = uuidv4().replaceAll("-", "");
      localStorage.setItem("userUUID", userUUID);
    }

    return userUUID;
  };

  getUserUUID();

  return (
    <>
      <Box
        height="100%"
        width="100%"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent={"center"}
        sx={{ p: 3 }}
      >
    <h1>EHR-QC</h1>
    <h3>This website provides web-interface for EHR-QC</h3>
        START
      </Box>
    </>
  );
};

export default FrontPage;
