import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Box, Button, CircularProgress, styled } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export const ImportFiles = ({ fileName, setState }) => {
  const [sourceFile, setSourceFile] = useState("");
  const [success, setSuccess] = useState(false);
  const [pending, setPending] = useState(false);
  const [alreadyExists, setAlreadyExists] = useState(false);
  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSourceFile(file.name);
      setPending(true);
      const formData = new FormData();
      formData.append("file", file);
      formData.append("fileName", fileName);
      axios
        .post("/api/uploadVocabulary", formData)
        .then((response) => {
          console.log(response.data);
          setSuccess(true);
          // setState(true);
        })
        .catch((error) => {
          console.error(error);
          setSourceFile("");
        })
        .finally(() => {
          setPending(false);
        });
    }
  };

  useEffect(() => {
    axios
      .post("/api/fetchFileWithPath", {
        filePath: "/app/vocabulary/" + fileName,
      })
      .then((response) => {
        setAlreadyExists(response.data.file_exists);
      });
  }, [fileName]);

  return (
    <Box display={"flex"} flexDirection={"column"} width={350}>
      {fileName}
      <Button
        component="label"
        role={undefined}
        variant="contained"
        tabIndex={-1}
        startIcon={<CloudUploadIcon />}
      >
        Upload file
        <VisuallyHiddenInput type="file" accept=".csv" onChange={handleFileUpload} />
      </Button>
      {pending && <CircularProgress />}
      {sourceFile && success && <p>File uploaded successfully</p>}
      {alreadyExists && <p>File already exists in our server</p>}
    </Box>
  );
};
