import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Box } from "@mui/system";
import React, { useMemo, useState } from "react";
import axios from "axios";
// import { Button, Card, Form, FormSelect } from "react-bootstrap";
import { StepDataContext } from "../contexts/StepDataContext";
import { useImport } from "../hooks/useImport";
import { formatData, getFormattedColumns } from "../utils/formatData";
import "./FileImport.css";
import { RenderTable } from "./Table/Table";
import {
  Button,
  styled,
  Card,
  CardContent,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Alert,
} from "@mui/material";

const column_mapping = {
  patients: {
    patient_id: null,
    gender: null,
    age: null,
    dob: null,
    dod: null,
  },
  admissions: {
    patient_id: null,
    episode_id: null,
    admittime: null,
    dischtime: null,
    deathtime: null,
    admission_type: null,
    admission_location: null,
    discharge_location: null,
    insurance: null,
    language: null,
    marital_status: null,
    ethnicity: null,
    edregtime: null,
    edouttime: null,
    hospital_expire_flag: null,
  },
  chartevents: {
    patient_id: null,
    episode_id: null,
    vital_id: null,
    charttime: null,
    storetime: null,
    itemid: null,
    value: null,
    valuenum: null,
    valueuom: null,
    warning: null,
  },
  labevents: {
    labevent_id: null,
    patient_id: null,
    episode_id: null,
    specimen_id: null,
    itemid: null,
    charttime: null,
    storetime: null,
    value: null,
    valuenum: null,
    valueuom: null,
    ref_range_lower: null,
    ref_range_upper: null,
    flag: null,
    priority: null,
    comments: null,
  },
  diagnosis: {
    diagnosis_id: null,
    patient_id: null,
    episode_id: null,
    charttime: null,
    diagnosis: null,
    diagnosis_description: null,
  },
};

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const FileImport = ({ sectionName, user_uuid }) => {
  const {
    fileColumns,
    fileData,
    handleFileChange,
    handleImport,
    resetFileData,
  } = useImport(sectionName, user_uuid);
  const [sourceFile, setSourceFile] = React.useState(null);
  const { stepData, updateStepData } = React.useContext(StepDataContext);

  const MemoizedRenderTable = React.memo(RenderTable);

  const columns = useMemo(
    () => Object.keys(column_mapping[sectionName]),
    [sectionName]
  );

  React.useEffect(() => {
    if (columns) {
      const columnsObject = columns.reduce((obj, column) => {
        obj[column] =
          stepData?.["import"]?.[sectionName]?.["column_mapping"]?.[column] ||
          null;
        return obj;
      }, {});

      updateStepData("import", {
        ...stepData["import"],
        [sectionName]: {
          ...stepData["import"][sectionName],
          column_mapping: {
            ...columnsObject,
          },
          fileData: stepData["import"][sectionName].fileData || null,
        },
      });
    }
  }, []);

  const [file, setFile] = useState(null);
  const [response, setResponse] = useState(null);
  const [sectionNameSQL, setSectionNameSQL] = useState(""); // State to hold the section name

  // const handleSQLFileChange = (event) => {
  //   const selectedFile = event.target.files[0];
  //   if (selectedFile) {
  //     setFile(selectedFile);
  //     // Extract the file name without the extension
  //     const fileName = selectedFile.name.replace(/\.[^/.]+$/, "");
  //     setSectionNameSQL(fileName);
  //   }
  // };

  const handleSQLFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      const fileName = selectedFile.name.replace(/\.[^/.]+$/, "") ;
      setSectionNameSQL(fileName);
    }
};


  function renderCardDetails() {
    if (fileData?.[0]) {
      return renderSuccess();
    } else {
      return renderImport();
    }
  }

  function renderSuccess() {
    return (
      <Box sx={{ maxWidth: `100%` }}>
        <FormControl fullWidth>
          {columns.map((columnName) => (
            <Box key={sectionName + columnName}>
              <FormControl fullWidth sx={{ m: 1 }}>
                <InputLabel id="select-label">{columnName}</InputLabel>
                <Select
                  labelId="select-label"
                  label={columnName}
                  value={
                    stepData?.["import"]?.[sectionName]?.["column_mapping"]?.[
                      columnName
                    ] || ""
                  }
                  onChange={(e) => {
                    let temp = stepData["import"];
                    temp[sectionName] = {
                      ...temp[sectionName],
                      column_mapping: {
                        ...temp[sectionName].column_mapping,
                        [columnName]: e.target.value,
                      },
                    };
                    updateStepData("import", temp);
                  }}
                >
                  <MenuItem value={null}>None</MenuItem>
                  {fileColumns.map((column) => (
                    <MenuItem key={sectionName + column} value={column}>
                      {column}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          ))}
        </FormControl>
        {fileData && fileColumns && (
          <MemoizedRenderTable
            excelExportName={sectionName}
            tableHeading={sectionName}
            columns={getFormattedColumns(fileColumns)}
            rows={formatData(fileData, fileColumns)}
          />
        )}
        <Button variant="danger" onClick={() => resetFileData()}>
          Cancel
        </Button>

        <div style={{
  maxWidth: '600px',
  margin: '0 auto',
  padding: '20px',
  backgroundColor: '#f8f9fa',
  borderRadius: '8px',
  boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
  textAlign: 'center'
}}>
  <h3 style={{
    color: '#333',
    fontSize: '1.5rem',
    marginBottom: '20px'
  }}>Select SQL file</h3>
  <input
    type="file"
    accept=".sql"
    onChange={handleSQLFileChange}
    style={{
      width: '100%',
      padding: '10px',
      margin: '0 0 20px',
      border: '2px solid #ced4da',
      borderRadius: '4px'
    }}
  />
  <Button
    variant="primary"
    onClick={handleSQLImport}
    style={{
      backgroundColor: '#007bff',
      borderColor: '#007bff',
      color: 'white',
      padding: '10px 20px',
      fontSize: '1rem',
      borderRadius: '4px',
      cursor: 'pointer',
      transition: 'background-color 0.3s ease'
    }}
    onMouseEnter={e => e.target.style.backgroundColor = '#0056b3'}
    onMouseLeave={e => e.target.style.backgroundColor = '#007bff'}
  >
    Import SQL
  </Button>
</div>

      </Box>
    );
  }

  const handleSQLImport = async (event) => {
    event.preventDefault();
    if (!file) {
      alert("Please select a SQL file first!");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);
    formData.append("user_uuid", user_uuid);
    formData.append("sectionName", sectionName);

    try {
      const result = await axios.post("/api/import/", formData);
      setResponse(result.data);
      alert("SQL file uploaded successfully!");
    } catch (error) {
      console.error("Error uploading SQL file:", error);
      alert("Error uploading SQL file.");
    }
  };

  function renderImport() {
    return (
      <>
        <Button
          component="label"
          role={undefined}
          variant="contained"
          tabIndex={-1}
          startIcon={<CloudUploadIcon />}
        >
          Upload file
          <VisuallyHiddenInput
            type="file"
            accept=".csv"
            onChange={(e) => {
              setSourceFile(e.target.files?.[0]);
              handleImport(e.target.files?.[0]);
            }}
          />
        </Button>
        {sourceFile && fileData && (
          <Alert severity="success">
            File successfully uploaded. Using file with name: {sourceFile.name}
          </Alert>
        )}
      </>
    );
  }

  return (
    <Card sx={{ minWidth: 275, margin: 2, maxWidth: `100%` }}>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          {sectionName}
        </Typography>
        {fileData ? renderCardDetails() : renderImport()}
      </CardContent>
    </Card>
  );
};

export default FileImport;
