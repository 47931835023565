import React from "react";

import { Alert, Box, Button, LinearProgress, Typography } from "@mui/material";
import usePolling from "../../../hooks/usePolling";
import { formatData, getFormattedColumns } from "../../../utils/formatData";
import { RenderTable } from "../../Table/Table";

export const ConceptCoverage = ({ existingSchema }: { existingSchema: boolean }) => {
  const { isPolling, error, callAPI, response, resetResponse } = usePolling();

  //requires schema_name and save_file
  const handleOnClick = () => {
    callAPI("/api/preprocess/conceptCoverage", "POST", {
      user_uuid: localStorage.getItem("userUUID"),
      schema_name: "ehrqc_" + localStorage.getItem("userUUID"),
      save_file: "concept_coverage.csv",
    });
  };

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      justifyContent={"center"}
      sx={{ pt: 3, gap: 2, maxWidth: `100%` }}
    >
      {!existingSchema && (
        <Alert severity="error">
          Concept coverage requires your existing data from standardisation. It seems that your data
          has been removed. Please run standardisation again.
        </Alert>
      )}
      {existingSchema && (
        <>
          <Typography variant="h4">Concept Coverage</Typography>
          <Typography variant="body1">
            This step involves running the concept coverage analysis on your standardised data from
            the standardisation sequence.
          </Typography>

          {!response && (
            <Button
              variant="contained"
              onClick={() => {
                handleOnClick();
              }}
            >
              Run Concept Coverage
            </Button>
          )}

          {isPolling && (
            <Box sx={{ width: "100%" }}>
              <LinearProgress />
            </Box>
          )}

          {error && (
            <Alert severity="error">
              Error has occured, please check configurations and try again.
            </Alert>
          )}

          {response && (
            <>
              <Button variant="contained" color="primary" onClick={() => resetResponse()}>
                Back
              </Button>
              <RenderTable
                excelExportName="Concept Coverage"
                tableHeading="Concept Coverage Results"
                rows={formatData(response.data, response.columns)}
                columns={getFormattedColumns(response.columns, 200)}
              />
            </>
          )}
        </>
      )}
    </Box>
  );
};
