import React, { useMemo } from "react";
import { CallAPISection } from "../CallAPISection";
import { StepDataContext } from "../../../contexts/StepDataContext";
import { getCheckedData } from "../../../utils/getStandardiseJSON";
import { Alert, Box, Button, Typography, styled } from "@mui/material";
import { ImportSectionCards } from "../../import/ImportSectionCards";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import axios from "axios";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export const ImportConceptMapping = () => {
  const { stepData } = React.useContext(StepDataContext);
  const [source_file, setSourceFile] = React.useState<File | null>(null);
  // get the data which are checked from importConfigSection.
  const importData = useMemo(() => getCheckedData(stepData.import), [stepData.import]);

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Handle submission of SQL code here
    // callAPI("/api/preprocess/extract", "POST", {});
    console.log(e.target.files?.[0]);
    const file = e.target.files?.[0];
    if (file) {
      setSourceFile(file);
      const formData = new FormData();
      const user_uuid = localStorage.getItem("userUUID");
      formData.append("file", file);
      formData.append("user_uuid", user_uuid ?? "");
      formData.append("sectionName", "concepts_mapped");

      axios
        .post("/api/import/", formData)
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      justifyContent={"center"}
      sx={{ pt: 3, gap: 2, maxWidth: `100%` }}
    >
      <Typography variant="h3">Import Concept Mapping</Typography>
      <Typography variant="body1">
        Integrating custom concept mappings for concepts that deviate from the standard
      </Typography>
      <Typography variant="body1">
        You can import your own or use the default if you dont supply one.
      </Typography>

      <Button
        component="label"
        role={undefined}
        variant="contained"
        tabIndex={-1}
        startIcon={<CloudUploadIcon />}
      >
        Upload file
        <VisuallyHiddenInput type="file" accept=".csv" onChange={handleFileUpload} />
      </Button>
      {source_file && (
        <Alert severity="success">
          File successfully uploaded. Using file with name: {source_file.name}
        </Alert>
      )}
      <CallAPISection
        data={importData}
        buttonLabel={"Import Concept Mapping Data Step"}
        successMessage={"Successfully imported the concept mapping provided."}
        stepName="importConceptMapping"
      />
    </Box>
  );
};
