import { ImportFiles } from "../components/import/ImportFiles";
import { Box, Button, Typography } from "@mui/material";
import usePolling from "../hooks/usePolling";

export const ImportVocabularyPage = () => {
  const { callAPI, isPolling, response } = usePolling();

  const filesNeeded = [
    "CONCEPT.csv",
    "VOCABULARY.csv",
    "DOMAIN.csv",
    "CONCEPT_CLASS.csv",
    "CONCEPT_RELATIONSHIP.csv",
    "RELATIONSHIP.csv",
    "CONCEPT_SYNONYM.csv",
    "CONCEPT_ANCESTOR.csv",
    "concept_mapped.csv",
  ];

  const handleOnClick = () => {
    callAPI("/api/standardise/importVocabulary", "POST", {});
  };

  return (
    <Box display={"flex"} flexDirection={"column"} sx={{ pt: 3, gap: 2 }}>
      <Typography variant="h3">Import Vocab</Typography>
      <Typography variant="body1">
        This is a one time process to import the vocabulary. Please upload the files and press
        import vocab{" "}
      </Typography>
      {filesNeeded.map((file) => {
        console.log(file);
        return <ImportFiles fileName={file} />;
      })}
      <Button
        variant="contained"
        onClick={() => {
          handleOnClick();
        }}
      >
        Import Vocab
      </Button>

      {isPolling && <Typography>Running in the background. You can close now.</Typography>}
    </Box>
  );
};
